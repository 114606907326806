'use client';

import { differenceInDays } from 'date-fns';
import { useLocale, useTranslations } from 'next-intl';

import {
  FundingProgressBar,
  FundingProgressBarProps,
} from '@/app/_components/ui/funding-progress-bar';
import { Funding } from '@/domain/project/types';
import { cn } from '@/lib/utils';

type FundingProgressBarCompleteInfoProps = Pick<
  Funding,
  'raisedAmount' | 'totalAmount' | 'fundingEndDate' | 'numberOfInvestors'
> & {
  fundingPercentage: number;
  progressBarBackground?: FundingProgressBarProps['background'];
  statutMiniFiche?: string;
  showsOptions: {
    showsProgressBar: boolean;
    showsAmountInvested: boolean;
    showsSoftGoal: boolean;
    showsNumberOfInvestors: boolean;
    showsAverageTicket: boolean;
    showsEndAt: boolean;
  };
};

const computeDifferenceInDaysDate = (
  fundingEndDate: Date | null,
): {
  count: number;
  tradKey: string;
  color: string;
} => {
  if (!fundingEndDate) {
    return {
      count: 0,
      tradKey: 'noEndDate',
      color: 'hsl(var(--destructive))',
    };
  }

  const differenceInDaysEndDate = differenceInDays(fundingEndDate, new Date());

  if (differenceInDaysEndDate > 10) {
    return {
      count: differenceInDaysEndDate,
      tradKey: 'daysLeft',
      color: 'hsl(var(--muted-foreground))',
    };
  }

  if (differenceInDaysEndDate > 5) {
    return {
      count: differenceInDaysEndDate,
      tradKey: 'daysLeft',
      color: 'hsl(var(--muted-complementary))',
    };
  }

  return {
    count: differenceInDaysEndDate > 0 ? differenceInDaysEndDate : 0,
    tradKey: differenceInDaysEndDate >= 0 ? 'daysLeft' : 'endDatePassed',
    color: 'hsl(var(--destructive))',
  };
};

const FundingProgressBarCompleteInfo = ({
  raisedAmount,
  totalAmount,
  fundingEndDate,
  fundingPercentage,
  progressBarBackground,
  showsOptions,
  statutMiniFiche,
}: FundingProgressBarCompleteInfoProps) => {
  const locale = useLocale();
  const t = useTranslations();

  const { count, tradKey, color } = computeDifferenceInDaysDate(fundingEndDate);
  const durationText =
    count > 0 || !statutMiniFiche
      ? t(`funding.${tradKey}`, { count })
      : statutMiniFiche;
  const durationColor =
    count > 0 || !statutMiniFiche ? color : 'hsl(var(--muted-foreground))';

  const percentageDifference = Math.round(
    ((raisedAmount - totalAmount) / totalAmount) * 100,
  );

  const { showsProgressBar, showsEndAt, showsAmountInvested } = showsOptions;

  if (showsProgressBar) {
    return (
      <div className="flex w-full grow flex-col gap-y-1">
        <div className="flex flex-row items-baseline justify-between gap-x-2">
          {showsAmountInvested ? (
            <p
              className={cn(
                'text-xs font-bold',
                raisedAmount > totalAmount
                  ? "mt-1 text-success after:absolute after:text-center after:text-sm after:leading-3 after:content-['✨']"
                  : '',
              )}
              data-testid="project-page-funding-amount"
            >
              {raisedAmount > totalAmount
                ? `${Number(raisedAmount).toLocaleString(locale, {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0,
                  })} (+${percentageDifference}%)`
                : Number(raisedAmount).toLocaleString(locale, {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0,
                  })}
            </p>
          ) : (
            <div className="mt-1 h-4" />
          )}
        </div>
        <FundingProgressBar
          current={raisedAmount}
          total={totalAmount}
          background={progressBarBackground}
        />
        <div className="flex flex-row justify-between">
          {/* <p className="truncate text-xs text-muted-foreground">
          {t('funding.numberOfInvestors', {
            count: numberOfInvestors,
          })}
        </p> */}
          {!!fundingEndDate && showsEndAt ? (
            <p
              className="max-w-14 text-xs lg:max-w-full"
              style={{ color: durationColor }}
            >
              {durationText}
            </p>
          ) : null}
          {showsAmountInvested ? (
            <p
              className="text-xs text-muted-foreground"
              data-testid="project-page-funding-percentage"
            >
              {t('funding.raisedPercentage', {
                percentage: Math.round(fundingPercentage),
              })}
            </p>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full grow flex-col gap-y-1">
      <div className="flex flex-row justify-end pt-1">
        {!!fundingEndDate && showsEndAt ? (
          <p
            className="max-w-14 text-xs lg:max-w-full"
            style={{ color: durationColor }}
          >
            {durationText}
          </p>
        ) : (
          <div className="h-4" />
        )}
      </div>
      <div className="grid h-2 grid-cols-[1fr_theme(spacing.2)_1fr] gap-2">
        <div className="rounded-full bg-alt-background" />
        <div className="size-2 rounded-full bg-alt-background" />
        <div className="rounded-full bg-alt-background" />
      </div>
    </div>
  );
};

export default FundingProgressBarCompleteInfo;
